/*------------------------------------------------------------------

Project        :   Claylist - Classifieds, Directory, Multipurpose Listing HTML Template
Version        :   V.1.0
Create Date    :   19/05/22
Copyright      :   Spruko Technologies Private Limited 
Author         :   SPRUKO™
Author URL     :   https://themeforest.net/user/spruko
Support	       :   support@spruko.com
License        :   http://themeforest.net/licenses/standard

-------------------------------------------------------------------*/

@import url(https://fonts.googleapis.com/css?family=Muli:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Maven+Pro:400,500,700);


/* ###### bootstrap ####### */
@import "variables";
@import "bootstrap/bootstrap-styles";
@import "bootstrap/accordion";
@import "bootstrap/alerts";
@import "bootstrap/avatar";
@import "bootstrap/badge";
@import "bootstrap/breadcrumbs";
@import "bootstrap/buttons";
@import "bootstrap/cards";
@import "bootstrap/carousel";
@import "bootstrap/dropdown";
@import "bootstrap/forms";
@import "bootstrap/grid";
@import "bootstrap/input-group";
@import "bootstrap/jumbotron";
@import "bootstrap/modal";
@import "bootstrap/navigation";
@import "bootstrap/pagination";
@import "bootstrap/panels";
@import "bootstrap/popover";
@import "bootstrap/progress";
@import "bootstrap/tables";
@import "bootstrap/tags";
@import "bootstrap/thumbnail";
@import "bootstrap/tooltip";



/* ###### custom ####### */

@import "custom/count-down";
@import "custom/custom-styles";
@import "custom/highlight";
@import "custom/images";
@import "custom/label";
@import "custom/list";
@import "custom/media";
@import "custom/navbar";
@import "custom/pricing";
@import "custom/rating";
@import "custom/tabs";
@import "custom/timeline";



/* ###### layout ####### */

@import "layout/color";
@import "layout/dark-style";
@import "layout/dashboard";
@import "layout/feather";
@import "layout/flags";
@import "layout/footers";
@import "layout/headers";
@import "layout/horizontal";
@import "layout/icons";
@import "layout/items";
@import "layout/loaders";
@import "layout/rtl";
@import "layout/sidebar";


/* ###### lib ####### */

@import "lib/check-box";
@import "lib/custom-control";
@import "lib/custom-range";
@import "lib/custom-switch";
@import "lib/date-picker";
@import "lib/file-inputs";
@import "lib/jvector";
@import "lib/range";
@import "lib/select2";
@import "lib/select-group";
@import "lib/selectize";



/* ###### template ####### */

@import "template/admin-nav";
@import "template/calendar";
@import "template/charts";
@import "template/chat";
@import "template/email";
@import "template/error-pages";
@import "template/ribbons";
@import "template/user-cards";
@import "template/weather-cards";
@import "template/widgets";



/* ###### util ####### */

@import "util/background";
@import "util/border";
@import "util/height";
@import "util/margin";
@import "util/padding";
@import "util/typography";
@import "util/width";









































