.user-card .card-block {
	.user-image {
		position: relative;
		margin: 0 auto;
		display: inline-block;
		padding: 5px;
		width: 110px;
		height: 110px;
	}
	.activity-leval li {
		display: inline-block;
		width: 15%;
		height: 4px;
		margin: 0 3px;
		background-color: #e9ebf9;
	}
	.counter-block {
		color: $white;
	}
	.user-social-link i {
		font-size: 30px;
	}
}