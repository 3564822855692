.tab-content> {
	.tab-pane {
		display: none;
	}
	.active {
		display: block;
	}
}

@media (max-width: 480px) {
	.tab-content.card-body {
		.btn {
			min-width: 1.625rem;
		}
		.progress-bar {
			display: none;
		}
	}
}

.tabs-menu ul li {
	a {
		padding: 10px 20px 11px 20px;
	}
	.active {
		color: $white;
		border-radius: 25px;
	}
}

.tabs-menu1 ul li {
	a {
		padding: 10px 20px 11px 20px;
	}
	.active {
		border-radius: 2px;
	}
}

.tabs-menu-body {
	padding: 15px;
	border: 1px solid $border;
	p:last-child {
		margin-bottom: 0;
	}
}

.tab-menu-heading {
	padding: 20px;
	border: 1px solid $border;
	border-bottom: 0;
}

.tab_wrapper {
	.content_wrapper .tab_content.active p:last-child {
		margin-bottom: 0;
	}
	>ul {
		li {
			border: 1px solid $border;
			border-top: 3px solid $border;
		}
		border-bottom: 1px solid $border;
	}
	&.right_side {
		.content_wrapper {
			border: 1px solid $border;
		}
		>ul {
			li {
				&.active {
					border-color: $border;
				}
				&:after {
					background: $border;
				}
			}
			border-bottom: 1px solid $border;
		}
	}
}

@media (min-width: 767px) {
	.tab-content #tab-11 {
		.item-card9-img {
			width: 400px;
		}
		.item-card9-imgs img,
		.item-card2-img img {
			height: 197px;
		}
	}
	.h-197 {
		height: 197px;
	}
}

#tab-11 .item-card2-img:hover a,
#tab-12 .item-card2-img:hover a {
	background: 0 0;
}

.tabs-menu ul.eductaional-tabs li a {
	padding: 10px 20px 11px 20px;
	background: $pink;
	border-radius: 23px;
	margin: 10px;
	color: $white;
}

@media (max-width: 480px) {
	.tabs-menu ul.eductaional-tabs li {
		a {
			margin: 0;
		}
		width: 100% !important;
	}
	.tab-content .tab-pane .card-body a {
		width: 100%;
		display: block;
		&.float-right {
			float: none !important;
		}
	}
}

@media (max-width: 768px) {
	.tabs-menu1 ul li a {
		display: block;
		width: 100%;
		border: 1px solid $border;
		margin: 1px;
		border-radius: 2px;
	}
}