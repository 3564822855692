.fileinput-button {
	float: left;
	margin-right: 4px;
	overflow: hidden;
	position: relative;
	background: none repeat scroll 0 0 $background;
	border: 1px solid #e6e6e6;
	input {
		cursor: pointer;
		direction: ltr;
		font-size: 23px;
		margin: 0;
		opacity: 0;
		position: absolute;
		right: 0;
		top: 0;
		transform: translate(-300px, 0) scale(4);
	}
}

.fileupload-buttonbar {
	.btn,
	.toggle {
		margin-bottom: 5px;
	}
}

.files .progress {
	width: 200px;
}

.fileupload-processing .fileupload-loading {
	display: block;
}

* html .fileinput-button {
	line-height: 24px;
	margin: 1px -3px 0 0;
}

@media (max-width: 767px) {
	.files {
		.btn span {
			display: none;
		}
		.preview * {
			width: 40px;
		}
		.name * {
			display: inline-block;
			width: 80px;
			word-wrap: break-word;
		}
		.progress {
			width: 20px;
		}
		.delete {
			width: 60px;
		}
	}
}