.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {
	color: $success;
}

.custom-control-input.is-valid~.custom-control-label::before,
.was-validated .custom-control-input:valid~.custom-control-label::before {
	background-color: #9eff3b;
}

.custom-control-input.is-valid~ {
	.valid-feedback,
	.valid-tooltip {
		display: block;
	}
}

.was-validated .custom-control-input:valid~ {
	.valid-feedback,
	.valid-tooltip {
		display: block;
	}
}

.custom-control-input.is-valid:checked~.custom-control-label::before,
.was-validated .custom-control-input:valid:checked~.custom-control-label::before {
	background-color: #78ed00;
}

.custom-control-input.is-valid:focus~.custom-control-label::before,
.was-validated .custom-control-input:valid:focus~.custom-control-label::before {
	box-shadow: 0 0 0 1px $background, 0 0 0 2px rgba(94, 186, 0, 0.25);
}

.custom-control-input.is-invalid~.custom-control-label,
.was-validated .custom-control-input:invalid~.custom-control-label {
	color: $danger;
}

.custom-control-input.is-invalid~.custom-control-label::before,
.was-validated .custom-control-input:invalid~.custom-control-label::before {
	background-color: #ec8080;
}

.custom-control-input.is-invalid~ {
	.invalid-feedback,
	.invalid-tooltip {
		display: block;
	}
}

.was-validated .custom-control-input:invalid~ {
	.invalid-feedback,
	.invalid-tooltip {
		display: block;
	}
}

.custom-control-input.is-invalid:checked~.custom-control-label::before,
.was-validated .custom-control-input:invalid:checked~.custom-control-label::before {
	background-color: #e23e3d;
}

.custom-control-input.is-invalid:focus~.custom-control-label::before,
.was-validated .custom-control-input:invalid:focus~.custom-control-label::before {
	box-shadow: 0 0 0 1px $background, 0 0 0 2px rgba(205, 32, 31, 0.25);
}

.custom-control-inline {
	display: -ms-inline-flexbox;
	display: inline-flex;
	margin-right: 1rem;
}

.custom-control-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
	&:active~.custom-control-label::before {
		color: $white;
		background-color: #d4e1f4;
	}
	&:disabled~.custom-control-label {
		color: $gray;
		&::before {
			background-color: $border;
		}
	}
}

.custom-control-label {
	margin-bottom: 0;
	position: static !important;
	vertical-align: middle;
	&::before {
		position: absolute;
		top: .25rem;
		left: 0;
		display: block;
		width: 1rem;
		height: 1rem;
		pointer-events: none;
		content: "";
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		background-color: $border;
	}
	&::after {
		position: absolute;
		top: .25rem;
		left: 0;
		display: block;
		width: 1rem;
		height: 1rem;
		content: "";
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 50% 50%;
	}
}

.custom-control {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	position: relative;
	display: block;
	min-height: 1.5rem;
	padding-left: 1.5rem;
}

.custom-controls-stacked .custom-control {
	margin-bottom: .25rem;
}

.custom-control-label:before {
	border: 1px solid rgba(167, 180, 201, 0.5);
	background-color: $white;
	background-size: .5rem;
}

.custom-control-description {
	line-height: 1.5rem;
}

.custom-control-label,
 ::-ms-backdrop {
	position: static;
}

.custom-control-input:checked~.custom-control-label::before {
	color: $white;
	border-color: $primary;
	background-color: $primary;
}