.text-monospace {
	font-family: Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
	text-align: justify !important;
}

.text-lowercase {
	text-transform: lowercase !important;
}

.text-uppercase {
	text-transform: uppercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.text-white {
	color: $white !important;
}

.text-success {
	color: $success !important;
}

a.text-success {
	&:focus,
	&:hover {
		color: #3adfab !important;
	}
}

.text-info {
	color: $info !important;
}

a.text-info {
	&:focus,
	&:hover {
		color: #1594ef !important;
	}
}

.text-warning {
	color: $yellow !important;
}

a.text-warning {
	&:focus,
	&:hover {
		color: $warning !important;
	}
}

.text-danger {
	color: $danger !important;
}

a.text-danger {
	&:focus,
	&:hover {
		color: #fb5b50 !important;
	}
}

.text-light {
	color: $light !important;
}

a.text-light {
	&:focus,
	&:hover {
		color: #dae0e5 !important;
	}
}

.text-dark {
	color: #504e70 !important;
}

.text-body {
	color: #3d4e67 !important;
}

.text-muted {
	color: #797896 !important;
}

.text-black-50 {
	color: $black-5 !important;
}

.text-white-50 {
	color: $white-5 !important;
}

.text-white-80 {
	color: $white-8 !important;
}

.text-hide {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}

.text-wrap {
	font-size: 14px;
	line-height: 1.66;
	> {
		 :first-child {
			margin-top: 0;
		}
		 :last-child {
			margin-bottom: 0;
		}
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-top: 1em;
		}
	}
}

.text-inherit {
	color: inherit !important;
}

.text-default {
	color: $color !important;
}

.text-muted-dark {
	color: #5f6877 !important;
}

.tracking-tight {
	letter-spacing: -0.05em !important;
}

.tracking-normal {
	letter-spacing: 0 !important;
}

.tracking-wide {
	letter-spacing: 0.05em !important;
}

.leading-none {
	line-height: 1 !important;
}

.leading-tight {
	line-height: 1.3 !important;
}

.leading-normal {
	line-height: 1.5 !important;
}

.leading-loose {
	line-height: 2 !important;
}

.text-blue {
	color: #3ba2ff !important;
}

.text-indigo {
	color: $indigo !important;
}

.text-purple {
	color: $purple !important;
}

.text-lightpink-red {
	color: #ff7088 !important;
}

.text-lightgreen {
	color: #26eda2 !important;
}

.text-pink {
	color: $pink !important;
}

.text-red {
	color: $danger !important;
}

.text-orange {
	color: $primary !important;
}

.text-yellow {
	color: $yellow !important;
}

.text-green {
	color: $success !important;
}

.text-teal {
	color: $teal !important;
}

.text-cyan {
	color: $cyan !important;
}

.text-gray {
	color: #858d97 !important;
}

.text-gray-dark {
	color: $dark !important;
}

.text-azure {
	color: $info !important;
}

.text-lime {
	color: $lime !important;
}