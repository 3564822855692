.tag {
	font-size: .75rem;
	color: #bbadda;
	background-color: $background;
	border-radius: 3px;
	padding: 0 .5rem;
	line-height: 2em;
	display: -ms-inline-flexbox;
	display: inline-flex;
	cursor: default;
	font-weight: 400;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

a.tag {
	text-decoration: none;
	cursor: pointer;
	transition: .3s color, .3s background;
	&:hover {
		background-color: rgba(110, 118, 135, 0.2);
		color: inherit;
	}
}

.tag-addon {
	display: inline-block;
	padding: 0 .5rem;
	color: inherit;
	text-decoration: none;
	background: rgba(0, 0, 0, 0.06);
	margin: 0 -.5rem 0 .5rem;
	text-align: center;
	min-width: 1.5rem;
	&:last-child {
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
	}
	i {
		vertical-align: middle;
		margin: 0 -.25rem;
	}
}

a.tag-addon {
	text-decoration: none;
	cursor: pointer;
	transition: .3s color, .3s background;
	&:hover {
		background: rgba(0, 0, 0, 0.16);
		color: inherit;
	}
}

.tag-avatar {
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 3px 0 0 3px;
	margin: 0 .5rem 0 -.5rem;
}

.tag-blue {
	background-color: $blue !important;
	color: $white;
}

.tag-indigo {
	background-color: $indigo !important;
	color: $white;
}

.tag-purple {
	background-color: $purple !important;
	color: $white;
}

.tag-pink {
	background-color: $pink !important;
	color: $white;
}

.tag-red {
	background-color: $danger !important;
	color: $white;
}

.tag-orange {
	background-color: $primary !important;
	color: $white;
}

.tag-yellow {
	background-color: $yellow !important;
	color: $white;
}

.tag-green {
	background-color: $success !important;
	color: $white;
}

.tag-teal {
	background-color: $teal !important;
	color: $white;
}

.tag-cyan {
	background-color: $cyan !important;
	color: $white;
}

.tag-white {
	background-color: $white !important;
	color: $white;
}

.tag-gray {
	background-color: $gray !important;
	color: $white;
}

.tag-gray-dark {
	background-color: $dark !important;
	color: $white;
}

.tag-azure {
	background-color: $info !important;
	color: $white;
}

.tag-lime {
	background-color: $lime !important;
	color: $white;
}

.tag-success {
	background-color: $success !important;
	color: $white;
}

.tag-info {
	background-color: $info !important;
	color: $white;
}

.tag-warning {
	background-color: $yellow !important;
	color: $white;
}

.tag-danger {
	background-color: $danger !important;
	color: $white;
}

.tag-light {
	background-color: $light !important;
	color: $white;
}

.tag-dark {
	background-color: $dark !important;
	color: $white;
}

.tag-rounded {
	border-radius: 50px;
	.tag-avatar {
		border-radius: 50px;
	}
}

.tags {
	margin-bottom: -.5rem;
	font-size: 0;
	>.tag {
		margin-bottom: .5rem;
		&:not(:last-child) {
			margin-right: .5rem;
		}
	}
}