.pattern:before {
	background: $primary-gradient;
}

.owl-controls .owl-page.active {
	border-color: $primary;
	opacity: 1;

	&:hover {
		border-color: $primary;
		opacity: 1;
	}
}

.owl-carousel:hover .owl-nav button,
.owl-nav button {
	color: $primary  !important;
}

a.bg-secondary {

	&:hover,
	&:focus {
		background-color: $secondary-hover  !important;
	}
}

button.bg-secondary {

	&:hover,
	&:focus {
		background-color: $secondary-hover  !important;
	}
}

.item-card7-overlaytext h4 {
	background: $primary;
}

.bg-background-color:before {
	background: $primary-gradient  !important;
}

.btn-primary {
	color: $white;
	background-color: $primary;
	border-color: $primary;

	&:hover {
		color: $white;
		background-color: $primary-hover;
		border-color: $primary-hover;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px $primary-shadow;
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: $primary;
		border-color: $primary;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: $primary;
			border-color: $primary;
		}
	}
}

.show>.btn-primary.dropdown-toggle {
	color: $white;
	background-color: $primary;
	border-color: $primary;
}

.btn-primary:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px $primary-shadow;
	}
}

.show>.btn-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px $primary-shadow;
}

.btn-outline-primary {
	color: $primary  !important;
	background-color: transparent;
	background-image: none;
	border-color: $primary  !important;

	&:hover {
		color: $white  !important;
		background-color: $primary;
		border-color: $primary  !important;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px $primary-shadow;
	}

	&.disabled,
	&:disabled {
		color: $primary;
		background-color: transparent;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: $primary;
			border-color: $primary;
		}
	}
}

.show>.btn-outline-primary.dropdown-toggle {
	color: $white;
	background-color: $primary;
	border-color: $primary;
}

.btn-outline-primary:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px $primary-shadow;
	}
}

.show>.btn-outline-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px $primary-shadow;
}

.btn-link {
	color: $primary  !important;
}

.alert-primary {
	color: $white;
	background-color: $primary;
	border-color: $primary;

	hr {
		border-top-color: #b7cded;
	}

	.alert-link {
		color: #172b46;
	}
}

.label-primary {
	background-color: $primary  !important;
}

.label-secondary {
	background-color: $secondary  !important;
}

.badge-primary {
	color: $white;
	background-color: $primary;

	&[href] {

		&:hover,
		&:focus {
			color: $white;
			text-decoration: none;
			background-color: $primary;
		}
	}
}

.bg-gradient-primary {
	background: $primary-gradient;
}

.bg-primary {
	background-color: $primary  !important;
}

a.bg-primary {

	&:hover,
	&:focus {
		background-color: $primary  !important;
	}
}

button.bg-primary {

	&:hover,
	&:focus {
		background-color: $primary  !important;
	}
}

.border-primary {
	border-color: $primary  !important;
}

.text-primary {
	color: $primary  !important;
}

a {
	&.text-primary {

		&:hover,
		&:focus {
			color: $primary-hover  !important;
		}
	}

	&.text-dark {

		&:hover,
		&:focus {
			color: $primary  !important;
		}
	}
}

.social-box.linkedin i {
	background: $primary;
}

.checkbox span:after,
.user-card .card-block .activity-leval li.active {
	background-color: $primary;
}

#count-down .clock-presenter .digit,
.notifyimg {
	background: $primary;
}

.drop-icon-wrap .drop-icon-item:hover {
	color: $primary;
}

.dropdown-item {

	&:hover,
	&:focus,
	&.active,
	&:active {
		color: $primary;
	}
}

.timeline__item:after {
	border: 6px solid $primary;
}

.custom-control-input:checked~.custom-control-label::before {
	background-color: $primary;
}

.form-checkbox .custom-control-input {

	&:checked~.custom-control-label::before,
	&:indeterminate~.custom-control-label::before {
		background-color: $primary  !important;
	}
}

.form-radio .custom-control-input:checked~.custom-control-label::before {
	background-color: $primary;
}

.form-select:focus {
	border-color: $primary;
}

.custom-file-input:focus~.custom-file-label {
	border-color: $primary;
	box-shadow: 0 0 0 2px $primary-shadow;

	&::after {
		border-color: $primary;
	}
}

.custom-file-label::after {
	background-color: $primary;
	border-left: 1px solid $primary;
}

.form-range {

	&::-webkit-slider-thumb,
	&::-moz-range-thumb,
	&::-ms-thumb {
		background-color: $primary;
	}
}

.nav-link.active {
	background-color: $primary;
}

.nav-pills {

	.nav-link.active,
	.show>.nav-link {
		background-color: $primary;
	}
}

.page-link:hover {
	color: $primary;
}

.page-item.active .page-link {
	background-color: $primary;
	border-color: $primary;
}

.range.range-primary {
	input[type="range"] {

		&::-webkit-slider-thumb,
		&::-moz-slider-thumb {
			background-color: $primary;
		}
	}

	output {
		background-color: $primary;
	}

	input[type="range"] {
		outline-color: $primary;
	}
}

.nav-tabs .nav-item1 .nav-link {

	&:hover:not(.disabled),
	&.active {
		background: $primary;
	}
}

.panel.price>.panel-heading {
	background: $primary;
	color: $white;
}

.heading-primary {
	background-color: $primary;
}

.page-header .breadcrumb-item a {
	color: $color;
}

.breadcrumb-item1 a:hover,
.panel-title1 a {
	color: $primary;
}

.header {
	background: linear-gradient(120deg, $secondary 60%, $primary 100%);
}

.header-bg {
	background: #eef2f9;
	/* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #eef2f9, $primary);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to right, #eef2f9, $primary);
}

.nav-tabs .nav-link {

	&:hover:not(.disabled),
	&.active {
		color: $white;
		background: $primary  !important;
	}
}

.admin-navbar {
	.nav-item.active .nav-link {
		color: $primary;

		.icon {
			color: $primary;
		}
	}

	.nav-link {

		&:hover,
		&:focus,
		&.active {
			color: $primary;
		}
	}

	.mega-dropdown .sub-item .section-label {
		color: $primary;
	}

	.sub-item ul a {
		&:active {
			background-color: $primary;
		}

		&:hover,
		&:focus {
			color: $primary;
			text-decoration: none;
			background-color: $light;
		}
	}
}

.expanel-primary {
	border-color: $primary  !important;

	>.expanel-heading {
		color: $white  !important;
		background-color: $primary  !important;
		border-color: $primary  !important;
	}
}

.error-img .form-control:focus {
	color: $white;
	background-color: $white-2;
	border-color: $primary;
	outline: 0;
	box-shadow: 0 0 0 2px $primary-shadow;
}

.avatar {
	background: $primary no-repeat center/cover;
}

.spinner {
	background: linear-gradient(120deg, $secondary 0%, $primary 100%);
}

.spinner-lg {
	background-color: $primary;
}

.double-bounce1,
.double-bounce2,
.cube1,
.cube2 {
	background: linear-gradient(120deg, $secondary 0%, $primary 100%);
}

.lds-heart div {
	background: $primary;

	&:after,
	&:before {
		background: $primary;
	}
}

.lds-ring div {
	border: 6px solid $primary;
	border-color: $primary transparent transparent transparent;
}

.lds-hourglass:after {
	border: 26px solid $primary;
	border-color: $primary transparent;
}

.mediaicon {
	border: 1px solid $primary;
	background: $primary;
}

a.chip:hover {
	background-color: $primary;
}

.highlight .s {
	color: $primary;
}

.selectgroup-input {
	&:checked+.selectgroup-button {
		border-color: $primary;
		z-index: 1;
		color: $primary;
		background: #f6f7fb;
	}

	&:focus+.selectgroup-button {
		border-color: $primary;
		z-index: 2;
		color: $primary;
		box-shadow: 0 0 0 2px $primary-shadow;
	}
}

.form-switch-input {
	&:checked~.form-switch-indicator {
		background: $primary;
	}

	&:focus~.form-switch-indicator {
		box-shadow: 0 0 0 2px $primary-shadow;
		border-color: $primary;
	}
}

.imagecheck-input:focus~.imagecheck-figure {
	border-color: $primary;
	box-shadow: 0 0 0 2px $primary-shadow;
}

.imagecheck-figure:before {
	background: $primary url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center (center / 50%) 50%;
	color: $white;
}

.colorinput-input:focus~.colorinput-color {
	border-color: $primary;
	box-shadow: 0 0 0 2px $primary-shadow;
}

.ui-datepicker .ui-datepicker-title {
	color: $primary;
}

#back-to-top {
	background: $primary;

	&:hover {
		background: $white;
		color: $primary;
		border: 2px solid $primary;
	}
}

#chat-message {
	background: $primary-gradient;
}

.tabs-menu ul li .active {
	background: $primary;
}

.tabs-menu1 ul li .active {
	border: 1px solid $primary  !important;
	color: $primary;
}

.wideget-user-tab .tabs-menu1 ul li .active {
	border-bottom: 2px solid $primary  !important;
}

.rating-stars .rating-stars-container .rating-star {

	&.is--active .fa-heart,
	&.is--hover .fa-heart {
		color: $primary;
	}
}

.message-feed:not(.right) .mf-content {
	background: $primary;
	color: $white;

	&:before {
		border-right-color: $primary;
	}
}

.msb-reply button {
	background: $primary;
}

.wizard-card {
	.moving-tab {
		background-color: $primary  !important;
	}

	.choice {

		&:hover .icon,
		&.active .icon {
			border-color: $primary  !important;
			color: $primary  !important;
		}
	}
}

.cal1 .clndr {
	.clndr-table {
		.header-days {
			background: $primary;
		}

		tr .day {

			&.today.event,
			&.my-today.event {
				background: $primary;
			}

			&.today:hover,
			&.my-today:hover {
				background: $primary;
				color: $white;
			}
		}
	}

	.clndr-controls .clndr-control-button {
		.clndr-previous-button {
			color: $primary;
			background: #e6f2fe;
		}

		.clndr-next-button {
			color: $primary;
			background: #e6f2fe;

			&:hover {
				background: $primary;
			}
		}

		.clndr-previous-button:hover {
			background: $primary;
		}
	}
}

.fc button {
	background: $primary;
}

.fc-event,
.fc-event-dot {
	background-color: $primary;
	color: #f1f1f1 !important;
}

#sidebar {
	.accordion-toggle i {
		background: linear-gradient(120deg, $secondary 60%, $primary 100%);
	}

	ul li.active>a {
		color: $primary;

		&:hover {
			color: $primary;
		}
	}

	li a[aria-expanded="true"].active:before {
		background: linear-gradient(120deg, $secondary 60%, $primary 100%);
	}

	ul li a:hover,
	.collapse li a:hover {
		color: $primary;
	}
}

.sweet-alert button {
	background-color: $primary  !important;
}

.label-primary.arrowed:before {
	border-right-color: $primary;
}

.widgetdate p,
.widgetbox p,
.datebox p {
	border-bottom: 2px solid $primary;
}

.arrow-ribbon.bg-primary:before {
	border-left: 15px solid $primary;
}

.arrow-ribbon2:before {
	border-left: 24px solid $primary;
}

.badge-offer.bg-primary:after,
.badge-offer1.bg-primary:after {
	border-top: 12px solid $primary;
}

.social-icons li:hover {
	background: $primary;
	color: $white;
}

.social li a:hover,
.footer-main a:hover,
.footer-links a:hover {
	color: $primary  !important;
}

.product-tags li a:hover {
	background: $primary;
	border-color: $primary;
}

.counter-icon {
	border: 1px solid $primary;

	i {
		color: $primary;
	}
}

.header-links li a {

	&.active,
	&:hover {
		background: $primary;
		color: $white;
	}
}

.card-pay .tabs-menu li a.active,
.item1-tabs-menu ul li .active {
	background: $primary  !important;
}

.items-blog-tab-heading .items-blog-tab-menu li .active {
	background: $primary  !important;
	color: $white;
}

.item2-gl-nav .item2-gl-menu li .active {
	color: $primary  !important;
}

.item-all-cat {
	.item-all-card:hover .item-all-text h5 {
		color: $primary  !important;
	}

	.category-type .item-all-card img {
		background: $primary-gradient;
	}
}

.carousel-indicators li.active {
	color: $white;
	background: $primary;
}

.item-cards7-ic.realestate-list li a:hover {
	color: $primary;
}

.item1-links a {
	&:hover {
		color: $primary;
	}

	&.active {
		background: rgba(241, 238, 247, 0.6);
		color: $primary;
		border-right: 2px solid $primary;
	}
}

.settings-tab .tabs-menu li a.active {
	background: $primary  !important;
}

.ads-tabs .tabs-menus ul li .active {
	background: $primary;
	color: $white;
}

.showmore-button-inner {
	border: 1px solid $primary;
	color: $primary;
}

.owl-nav button {
	color: $primary  !important;
}

.owl-carousel:hover .owl-nav button {
	color: $primary  !important;
}

.owl-nav>div i {
	color: $primary;
}

.tabs-menu ul.eductaional-tabs li .active {
	background: $primary;
}

.register-right .nav-tabs .nav-link:hover {
	background: $primary;
	color: $white;
}

.gradient-icon {
	background: linear-gradient(120deg, #3ba2ff 0%, $primary 100%);
}

.axgmap-img {
	border: 3px solid $primary;
}

.horizontalMenu>.horizontalMenu-list>li {
	> {
		.horizontal-megamenu .menu_form input {

			&[type="submit"],
			&[type="button"] {
				background-color: $primary;
				color: $white;
			}
		}

		a.active {
			background-color: $white-2;
			color: $white;
		}
	}

	&:hover>a {
		background-color: $primary;
		color: $white  !important;

		.fa {
			background-color: $primary;
			color: $white  !important;
		}
	}

	>ul.sub-menu>li {
		&:hover>a {
			background-color: $primary;
			color: $white  !important;
		}

		>ul.sub-menu>li {

			&:hover>a,
			>ul.sub-menu>li:hover a {
				background-color: $primary;
				color: $white  !important;
			}
		}
	}
}

code {
	color: $primary;
}

#gdpr-cookie-message {

	h4,
	h5 {
		color: $primary;
	}

	a {
		color: $primary;
		border-bottom: 1px solid $primary;

		&:hover {
			border-bottom: 1px solid $primary;
			transition: all 0.3s ease-in;
			color: $primary;
		}
	}
}

button#gdpr-cookie-advanced {
	color: $white;
	background-color: $primary  !important;
}

.ui-widget-header,
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
	background: $primary;
}

.side-menu li a.active:before {
	background: linear-gradient(120deg, $primary 60%, #2ddcd3 100%);
	box-shadow: 0px 0px 14px 0px $primary;
}

.app-sidebar {
	.side-menu li {

		&.active>a,
		a.active {
			color: $primary;
		}
	}

	.slide-menu li.active>a {
		color: $primary;
	}

	.side-menu li {

		ul li a:hover,
		a:hover {
			color: $primary;
		}
	}
}

.navtab-wizard.nav-tabs .nav-link {

	&.active,
	&:hover {
		color: $white;
		background: $primary;
	}
}

.form-inline .nav-search .btn {
	border: 1px solid $primary;
}

.wrapper>h1 span {
	border-bottom: 2px solid $primary;
	color: $primary;
}

.tab_wrapper {
	>ul li.active {
		border-color: $primary;
		background: $primary;
		color: $white;
	}

	.controller span {
		background: $primary;
	}

	.content_wrapper .accordian_header.active {
		color: $primary;

		&:after {
			background: $primary;
		}

		.arrow {
			border-top: 3px solid $primary !important;
			border-left: 3px solid $primary !important;
		}
	}

	&.left_side>ul li.active:after,
	&.right_side>ul li.active:after {
		background: $primary !important;
	}
}

.addui-slider .addui-slider-track {

	.addui-slider-range,
	.addui-slider-handle:after {
		background: $primary;
	}
}

.accordionjs .acc_section.acc_active>.acc_head {
	background: $primary;
}

.perfect-datetimepicker tbody td.selected {
	border: 1px solid $primary;
	background-color: $primary;
}

div.conv-form-wrapper div {
	&.options div.option {
		border: 1px solid $primary;
		color: $primary;
	}

	&#messages div.message.from {
		background: $primary;
	}

	&.options div.option.selected {
		background: $primary  !important;
		color: $white;
	}
}

div.conv-form-wrapper div.options div.option.selected:hover {
	background: $primary  !important;
}

button.submit.glow {
	box-shadow: 0 0 10px 5px $primary-4  !important;
}

.list-group-transparent .list-group-item.active {
	background: $secondary  !important;
}

form.convFormDynamic button.submit {
	border: 1px solid $primary;
	background: $primary;

	&:hover {
		background: $primary;
		color: $white;
	}
}

.exzoom {
	.exzoom_nav .exzoom_nav_inner span.current {
		border: 1px solid $primary;
	}

	.exzoom_btn a {
		color: $primary;
	}
}

.prev:hover {
	transform: translate(-5px, 0px);
	color: $primary  !important;
}

.next:hover {
	color: $primary  !important;
}

.g_f-s {
	.prev:hover i {
		transform: translate(-5px, 0px);
		color: $primary;
	}

	.next:hover i {
		transform: translate(5px, 0px);
		color: $primary;
	}

	.close-button>*:hover {
		color: $primary;
	}
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a:hover {
	color: $primary  !important;
}

.register {
	background: linear-gradient(120deg, #4f4ac7 0%, $primary 100%);
}

.btnRegister {
	background: $primary;
}

.register .nav-tabs .nav-link.active {
	color: $primary;
	border: 1px solid $primary;
}

.pretty {

	input:checked~.state.p-primary label:after,
	&.p-toggle .state.p-primary label:after {
		background-color: $primary  !important;
	}

	input:checked~.state.p-primary-o label:before,
	&.p-toggle .state.p-primary-o label:before {
		border-color: $primary;
	}

	input:checked~.state.p-primary-o {

		.icon1,
		.svg,
		svg {
			color: $primary;
			stroke: $primary;
		}
	}

	&.p-toggle .state.p-primary-o {

		.icon1,
		.svg,
		svg {
			color: $primary;
			stroke: $primary;
		}
	}

	&.p-default:not(.p-fill) input:checked~.state.p-primary-o label:after {
		background-color: $primary  !important;
	}

	&.p-switch {
		input:checked~.state.p-primary:before {
			border-color: $primary;
		}

		&.p-fill input:checked~.state.p-primary:before {
			background-color: $primary  !important;
		}
	}
}

.owl-carousel button.owl-dot {
	background: $primary-shadow  !important;

	&.active {
		background: $primary  !important;
	}
}

.bg-background:before,
.bg-background2:before,
.bg-background-color:before,
.bg-background3:before,
.bg-background-5:before,
.bg-background-6:before,
.slider-header .item:before {
	background: $primary-gradient;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
	box-shadow: 0 0 0 2px $primary-shadow;
}

.custom-control-input:focus~.custom-control-label::before {
	box-shadow: 0 0 0 1px $light, 0 0 0 2px $primary-shadow;
}

.form-range {

	&::-webkit-slider-thumb:focus,
	&::-moz-range-thumb:focus,
	&::-ms-thumb:focus {
		outline: none;
		box-shadow: 0 0 0 1px $light, 0 0 0 2px $primary-shadow;
	}
}

.selectize-input.focus {
	border-color: $blue;
	box-shadow: 0 0 0 2px $primary-shadow;
}

.item-card7-imgs:hover a {
	background: $primary-gradient;
}

.item-card2-img:hover a {
	background: transparent;
}

@media (max-width: 992px) {
	.nav-item.with-sub .sub-item {
		border-top: 2px solid $primary;
	}
}

@media (max-width: 480px) {
	.zoom-container .arrow-ribbon2:before {
		border-top: 17px solid transparent;
		border-left: 17px solid $primary;
		border-bottom: 17px solid transparent;
	}
}

.bg-secondary {
	background-color: $secondary  !important;
}

a.bg-secondary {

	&:hover,
	&:focus {
		background-color: $secondary  !important;
	}
}

button.bg-secondary {

	&:hover,
	&:focus {
		background-color: $secondary  !important;
	}
}

.btn-secondary {
	color: $white;
	background-color: $secondary;
	border-color: $secondary;

	&:hover {
		color: $white;
		background-color: $secondary;
		border-color: $secondary;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px $secondary-shadow;
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: $secondary;
		border-color: $secondary;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: $secondary;
			border-color: $secondary;
		}
	}
}

.show>.btn-secondary.dropdown-toggle {
	color: $white;
	background-color: $secondary;
	border-color: $secondary;
}

.btn-secondary:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px $secondary-shadow;
	}
}

.show>.btn-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px $secondary-shadow;
}

.accent-2 {
	border-top: 2px solid $primary  !important;
}

.header-main i {
	color: $primary;
}

.item-card {
	&:hover .item-card-desc:before {
		background: $primary-gradient;
	}
}

@media (min-width: 992px) {
	.sticky-wrapper.is-sticky .horizontalMenu>.horizontalMenu-list>li>a.active {
		background-color: $primary;
		color: $white;
	}
}

.arrow-ribbon.bg-secondary:before {
	border-left: 15px solid $secondary;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: $primary  !important;
}

.sticky-wrapper.relative.banners .horizontalMenu>.horizontalMenu-list>li {

	>a.active,
	&:hover>a {
		background-color: $primary;
	}
}

.pricingTable2.pink .pricing-plans {
	background-color: $primary;
}

.pricingTable {
	.title:after {
		border-left: 26px solid $primary;
	}
}

.construction-image:before {
	content: "";
	background: $primary-gradient;
}

.construction {
	z-index: 1;
}

.countdown-timer-wrapper .timer .timer-wrapper .time {
	color: $primary;
	background: $white-0;
}

.pricingTable {
	&:hover .pricingTable-signup {
		background: $primary;
		color: $white;
	}

	.pricingTable-signup {
		color: $primary;
	}

	&:hover .title {
		background: $primary;
		color: $white;
	}

	&.advance-pricing {
		border: 1px solid $primary;
	}

	&:hover {
		border: 1px solid $primary;

		.title {
			&:before {
				border-right-color: $primary;
			}

			&:after {
				border-left-color: $primary;
			}
		}
	}

	.title {
		&:before {
			content: "";
			border-right: 26px solid $primary;
		}

		&:after {
			content: "";
			border-right: 26px solid $primary;
			border-right: none;
			border-left: 26px solid $primary;
		}

		background: $primary;
		color: $white;
	}
}

@media (max-width: 991px) {
	.horizontalMenu>.horizontalMenu-list>li>a.active {
		background-color: $primary;
		color: $white;
	}
}

.bg-card-light:hover {
	border-color: $primary;
}

.status-border:hover {
	border: 1px solid $primary  !important;

	i {
		color: $primary  !important;
	}

	svg {
		fill: $primary  !important;
		;
	}
}

.bg-card {
	&:hover {
		background: $primary;
		color: $white;
	}

	.cat-img i {
		color: $primary;
	}

	&:hover .cat-img i {
		color: $white;
	}
}

.sticky-wrapper.absolute.banners .horizontalMenu>.horizontalMenu-list>li>a.active {
	background-color: $primary;
	color: $white  !important;
}

.badge-secondary {
	background-color: $secondary;

	&[href] {

		&:focus,
		&:hover {
			background-color: $secondary;
		}
	}
}

.badge-offer {
	&.bg-secondary:after {
		border-top: 12px solid $secondary;
	}
}

.badge-offer1 {
	&.bg-secondary:after {
		border-top: 12px solid $secondary;
	}
}

.breadcrumb-item {
	&.active {
		color: $primary;
	}
}

.btn-secondary {
	color: $white  !important;
	background-color: $secondary;
	border-color: $secondary;

	&:hover {
		color: $white;
		background-color: $secondary;
		border-color: $secondary;
	}

	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px $secondary-shadow;
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: $secondary;
		border-color: $secondary;
	}

	&:not(:disabled):not(.disabled) {

		&.active,
		&:active {
			color: $white;
			background-color: $secondary;
			border-color: $secondary;
		}
	}
}

.show>.btn-secondary.dropdown-toggle {
	color: $white;
	background-color: $secondary;
	border-color: $secondary;
}

.btn-secondary:not(:disabled):not(.disabled) {

	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px $secondary-shadow;
	}
}

.show>.btn-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px $secondary-shadow;
}

.btn.dropdown-toggle {
	&.btn-primary~.dropdown-menu .dropdown-plus-title {
		border-color: $primary  !important;
	}
}

.modal-header {
	.close {
		&.btn {
			background-color: $secondary;
			border-color: $secondary;

			&:hover {
				background-color: $secondary-hover;
			}
		}
	}
}

.expanel-secondary {
	border-color: $secondary  !important;
}

.expanel-secondary>.expanel-heading {
	color: $white  !important;
	background-color: $secondary  !important;
	border-color: $secondary  !important;
}

.pop-primary {
	color: $primary;
}

.popsecondary {
	color: $secondary;
}

.label {
	&.arrowed-in {
		&:before {
			border-right-color: $primary;
		}
	}

	&.arrowed:before {
		border-right-color: $primary;
	}
}

.bg-secondary {
	background-color: $secondary  !important;
}

a.bg-secondary {

	&:focus,
	&:hover {
		background-color: $secondary  !important;
	}
}

button.bg-secondary {

	&:focus,
	&:hover {
		background-color: $secondary  !important;
	}
}

.text-secondary {
	color: $secondary  !important;
}

a.text-secondary {

	&:focus,
	&:hover {
		color: $secondary  !important;
	}
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a.active {
	color: $primary  !important;

	i {
		color: $primary  !important;
	}
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a.active {
	color: $primary  !important;
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a.active {
	color: $primary  !important;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
	background-color: $primary;
	border-color: $primary;
}

.form-control {
	&::-webkit-file-upload-button {
		background-color: $primary;
	}

	&:hover[type="file"]:not(:disabled):not([readonly])::-webkit-file-upload-button {
		background-color: $primary;
	}

	&.example-file-input-custom {
		&::after {
			background-color: $primary;
		}
	}
}

.my-dash.app-sidebar .side-menu li a.active {
	background: transparent;
	color: $primary  !important;
	border-right: 2px solid $primary;

	i {
		color: $primary  !important;
	}
}

.app-sidebar {
	.side-menu__item.active {
		background: $primary-gradient  !important;
		color: $white  !important;

		&:hover {
			background: $primary-gradient  !important;
			color: $white  !important;
		}
	}

	&:before {
		background: $primary-gradient;
	}
}

.app-sidebar-footer {
	background: $primary-gradient;
}

.masthead .masthead-bg {
	background: $primary-gradient  !important;
}

@media (max-width: 992px) {
	.search-show .nav-search {
		background: $primary-gradient;
	}
}

.onoffswitch2-checkbox:checked+ {
	.onoffswitch2-label {
		background-color: $primary;
	}

	.onoffswitch-label2,
	.onoffswitch2-label:before {
		border-color: $primary;
	}
}

.app.sidebar-mini .app-sidebar .side-menu li.slide a:hover,
.app.sidebar-mini .app-sidebar .side-menu li.slide a:hover {
	background: $primary-gradient;
	color: $white;
}

.app.sidebar-mini .app-sidebar .side-menu li.slide a:hover .side-menu__label {
	color: $white;
}

.app.sidebar-mini .app-sidebar .side-menu li.slide ul li a:hover,
.app.sidebar-mini .app-sidebar .side-menu li.slide ul li a:hover {
	background: transparent;
	color: $primary;
}

.app.sidebar-mini .app-sidebar .side-menu li.slide ul li a:hover .side-menu__label {
	color: $primary;
}

.app.sidebar-mini .app-sidebar .side-menu li.slide.is-expanded a.side-menu__item {
	background: $primary-gradient;
	color: $white;
}

.app.sidebar-mini .app-sidebar .side-menu li.slide.is-expanded a.side-menu__item {
	background: $primary-gradient;
	color: $white;
}

.app.sidebar-mini .app-sidebar .side-menu li.slide.is-expanded .slide-menu .sub-slide.is-expanded .sub-slide-item {
	color: $primary;
}

.app.sidebar-mini .app-sidebar .side-menu li.slide.is-expanded .slide-menu .sub-slide.is-expanded .sub-slide-menu .sub-slide2.is-expanded .sub-slide-item2 {
	color: $primary;
}

.alert-secondary {
	color: $white;
	background-color: $secondary;
	border-color: $secondary;
}

.btn-outline-secondary {
	color: $secondary  !important;
	background-color: transparent;
	background-image: none;
	border-color: $secondary  !important;

	&:hover {
		color: $white  !important;
		background-color: $secondary;
		border-color: $secondary  !important;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px $secondary-shadow;
	}

	&.disabled,
	&:disabled {
		color: $secondary;
		background-color: transparent;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: $secondary;
			border-color: $secondary;
		}
	}
}

.show>.btn-outline-secondary.dropdown-toggle {
	color: $white;
	background-color: $secondary;
	border-color: $secondary;
}

.btn-outline-secondary:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px $secondary-shadow;
	}
}

.show>.btn-outline-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px $secondary-shadow;
}

.btn-check:active+.btn-outline-primary,
.btn-check:checked+.btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
	color: $white  !important;
	background-color: $primary;
	border-color: $primary;
}

.bg-secondary-transparent {
	background-color: $secondary-transparent;
}

.bg-primary-transparent {
	background-color: $primary-transparent;
}

.btn-check:focus+.btn-outline-primary,
.btn-outline-primary:focus {
	box-shadow: 0 0 0 0.25rem $primary-shadow;
}

.btn-check:active+.btn-outline-primary:focus,
.btn-check:checked+.btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
	box-shadow: 0 0 0 0.25rem $primary-shadow;
}

.btn-check:active+.btn-outline-secondary,
.btn-check:checked+.btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
	color: $white  !important;
	background-color: $secondary;
	border-color: $secondary;
}

.btn-check:active+.btn-outline-secondary:focus,
.btn-check:checked+.btn-outline-secondary:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus,
.btn-outline-secondary:active:focus {
	box-shadow: 0 0 0 0.25rem $secondary-shadow;
}

.tag-primary {
	background-color: $primary  !important;
	color: $white;
}

.tag-secondary {
	background-color: $secondary  !important;
	color: $white;
}

.breadcrumb-item1.active {
	color: $primary;
}

.breadcrumb-item2.active {
	color: $primary;
}

#usa_map,
#german,
#russia {
	svg {
		.jqvmap-region {
			fill: $primary;
		}
	}
}

.arrow-ribbon {
	&.bg-secondary:before {
		border-left: 15px solid $secondary;
	}
}

.ribbon-2 {
	&:after {
		border-top: 13px solid $secondary;
	}

	span {
		background: $secondary;

		&:before {
			background: $secondary;
		}

		&:after {
			background: $secondary;
		}
	}
}

button {
	&#ihavecookiesBtn {
		background: $secondary;
	}
}

#gdpr-cookie-message {
	button {
		background: $secondary;
	}
}

.onoffswitch2-checkbox:checked+.onoffswitch2-label {
	background-color: $primary;
}

.onoffswitch2-checkbox:checked+.onoffswitch-label2,
.onoffswitch2-checkbox:checked+.onoffswitch2-label:before {
	border-color: $primary;
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a.active:hover {
	color: $white  !important;
}

.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a.active:hover {
	color: $white  !important;
}

.horizontal-main .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li:hover>a {
	color: $white  !important;
}

.app-sidebar-footer {
	background: $primary-gradient  !important;
}

.nav-link:focus,
.nav-link:hover {
	color: $primary;
}

.progress-bar {
	background-color: $secondary;
}
.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
	color: #fff;
	background-color: $secondary;
	border-color: $secondary;
	box-shadow: 0 0 0 0.25rem $secondary-5;
}